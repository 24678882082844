import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import bidraIFigma1 from "../../../static/img/bidra-i-figma/bidra-i-figma1.png";
import bidraIFigma2 from "../../../static/img/bidra-i-figma/bidra-i-figma2.png";
import bidraIFigma3 from "../../../static/img/bidra-i-figma/bidra-i-figma3.png";
import bidraIFigma4 from "../../../static/img/bidra-i-figma/bidra-i-figma4.png";
import bidraIFigma5 from "../../../static/img/bidra-i-figma/bidra-i-figma5.png";
import bidraIFigma6 from "../../../static/img/bidra-i-figma/bidra-i-figma6.png";
import bidraIFigma7 from "../../../static/img/bidra-i-figma/bidra-i-figma7.png";
import bidraIFigma8 from "../../../static/img/bidra-i-figma/bidra-i-figma8.png";
export const _frontmatter = {
  "title": "Guide for Figma",
  "order": 5,
  "template": "default",
  "introtext": "Under kan du lese mer om hvordan du bidrar til designsystem-bibliotekene i Figma"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Opprett branch`}</h2>
    <p>{`Når du skal lage forslag til endringer eller nye komponenter i designsystemet i Figma, er det viktig at du først lager en ny «branch». Det er en kopi av designsystem-fila, som du kan gjøre endringene dine på, og som designsystem-teamet kvalitetssikrer før endringene «merges» (slås sammen med hovedfila) og publiseres ut i biblioteket.`}</p>
    <p>{`Et godt tips er å heller lage flere små brancher som kan merges inn fortere, enn større brancher som blir liggende lenge, for å unngå «unødvendige» merge-konflikter.`}</p>
    <p>{`Gjør dette for å opprette en ny branch:`}</p>
    <ol>
      <li parentName="ol">{`Finn designsystem-filen du vil gjøre endringene i`}</li>
    </ol>
    <img src={bidraIFigma1} alt="Skjermbilde av Designsystemprosjektet i Figma" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Gå inn i filen`}</li>
    </ol>
    <img src={bidraIFigma2} alt="Skjermbilde av filen Profil og komponenter i Figma" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Klikk på pila ved siden av navnet på filen og velg «Create branch...»`}</li>
    </ol>
    <img src={bidraIFigma3} alt="Skjermbilde av hvordan man oppretter ny branch i Figma" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Gi branchen et kort navn som beskriver endringene du tenker å gjøre og klikk på «Create»`}</li>
    </ol>
    <img src={bidraIFigma4} alt="Skjermbilde av navngiving ny branch i Figma" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Du har laget en ny branch, og kan sette i gang med å lage endringsforslaget ditt!`}</li>
    </ol>
    <img src={bidraIFigma5} alt="Skjermbilde av Figma hvor man jobber i egen branch" className="sb1ds-layout__spacing-bottom--md" />
    <p>{`Husk å dobbeltsjekke at du er inne på branchen din når du gjør disse endringene`}</p>
    <img src={bidraIFigma6} alt="Skjermbilde av verktøylinjen i figma hvor man ser hvilken branch man jobber i" className="sb1ds-layout__spacing-bottom--md" />
    <p>{`For å gå inn i branchen igjen senere finner du bare designsystem-filen i oversikten, klikker på nedtrekkslisten som vises over filen, og velger branchen der.`}</p>
    <h2>{`Send forespørsel til designsystem-teamet`}</h2>
    <p>{`Når du har gjort endringene og er klar til å sende inn forslaget ditt:`}</p>
    <ol>
      <li parentName="ol">{`Klikk på pila ved siden av filnavnet og velg «Review and merge changes»`}</li>
    </ol>
    <img src={bidraIFigma7} alt="Skjermbilde fra Figma som viser hvor du finner «Review and merge»" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Under «Reviewers», legg til Ragnhild Lie, Sindre Säfvenbom og Anders Johnsen fra designsystem-teamet ved å klikke på «Add»`}</li>
    </ol>
    <img src={bidraIFigma8} alt="Skjermbilde fra Figma av «Branch review»" className="sb1ds-layout__spacing-bottom--md" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Klikk på «Request review»`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Designsystem-teamet får melding om at du har lagt inn et forslag, og ser på det så fort vi kan. Du får beskjed når endringene dine blir publisert ut i designsystemet`}</p>
      </li>
    </ol>
    <h2>{`Husk at det også må kodes`}</h2>
    <p>{`Når vi kommer med forslag til endringer eller nye komponenter i Figma-bibliotekene, må vi huske på at det også skal implementeres i komponentbiblioteket som utviklerne bruker på GitHub – og motsatt.`}</p>
    <p>{`Dette er viktig for at bibliotekene holder seg oppdaterte og synkroniserte.`}</p>
    <p>{`Forslagene vil ikke bli publisert i Figma-biblioteket før vi også har en kodet variant vi kan publisere i biblioteket på GitHub.`}</p>
    <p>{`Hvis du har laget en branch med forslag til endringer eller ny komponent i Figma, kan du alliere deg meg utviklere på teamet ditt som koder samme endringer eller komponent i designsystemet på GitHub og lager en pull request som designsystem-teamet kvalitetssikrer før endringene publiseres i komponentbiblioteket.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      